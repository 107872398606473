<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">采购计划基本信息</el-col>
    </el-row>
    <el-form ref="Form" :disabled="fordisabled" :model="planForm" :rules="rules" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="采购计划单号" prop="purchasePlanCode">
            <el-input v-model="planForm.purchasePlanCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态" prop="purchaseStatus">
            <el-select v-model="planForm.purchaseStatus" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="草稿" value="0" />
              <el-option label="审批中" value="1" />
              <el-option label="审批通过" value="2" />
              <el-option label="部分转订单" value="3" />
              <el-option label="已完成" value="4" />

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="数量" prop="totalPairs">
            <el-input v-model="planForm.totalPairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备货渠道" prop="stockChannelName">
            <el-input v-model="planForm.stockChannelName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人" prop="createByName">
            <el-input v-model="planForm.createByName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建时间" prop="createTime">
            <el-input v-model="planForm.createTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="BU" prop="operatorGroup">
            <el-input v-model="planForm.operatorGroup" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Operator" prop="operatorUser">
            <el-input v-model="planForm.operatorUser" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计划类型" prop="type">
            <el-radio-group v-model="planForm.type">
              <el-radio :label="0">普通计划</el-radio>
              <el-radio :label="1">提前备货计划</el-radio>
              <el-radio :label="2">超买计划</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="planForm.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col></el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">
        <span>计划明细</span>
      </el-col>
    </el-row>
    <el-table
      ref="uploadTableDataRef"
      class="mb-3"
      :data="planTable"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="style,color,size"
        label="规格型号"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />

      <el-table-column
        prop="platformName"
        label="平台"
        width="100"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        width="100"
        align="center"
      />
      <el-table-column prop="position" label="定位" width="100" align="center" />
      <el-table-column prop="negative" label="是否负现金流" width="140" align="center" />
      <el-table-column
        width="120"
        prop="expectPairs"
        label="数量"
        align="center"
      />
      <el-table-column
        prop="shoeFoldFlag"
        label="鞋是否折叠"
        width="100"
        align="center"
      />
      <el-table-column
        prop="productPositionI18"
        label="产品定位"
        width="100"
        align="center"
      />
      <el-table-column
        prop="expectDeliveryDate"
        label="期望交货日期"
        width="120"
        align="center"
      />
      <el-table-column label="翻单建议数量" width="130" align="center" prop="reorderRecdQty" />
      <el-table-column label="销售预测版本" width="130" align="center" prop="versionCode" />
      <el-table-column label="预测销量" width="130" align="center" prop="fcstSaleQty" />
      <el-table-column label="预测退货" width="130" align="center" prop="fcstReturnQty" />
      <el-table-column label="PO/PR未结数量" width="130" align="center" prop="prPoUnclearQty" />
      <el-table-column label="RPS" width="130" align="center" prop="rps" />
      <el-table-column label="MPS" width="130" align="center" prop="mps" />
      <el-table-column label="近30天销量" width="130" align="center" prop="recentSaleQty" />
      <el-table-column label="近30天退货" width="130" align="center" prop="recentReturnQty" />
      <el-table-column label="采购LT" width="130" align="center" prop="ltDays" />
      <el-table-column label="TurnOVer" width="130" align="center" prop="turnover" />
      <el-table-column label="翻单周期" width="130" align="center" prop="replenishCycle" />
      <el-table-column label="平均退货率（%）" width="130" align="center" prop="avgReturnRate" />
      <el-table-column label="库存汇总" width="130" align="center" prop="totalInventoryPairs" />
      <el-table-column label="预留安全库存" width="130" align="center" prop="reserveSafeStock" />
      <el-table-column label="预留外可用库存" width="130" align="center" prop="notReservedAbleStock" />

    </el-table>
    <el-row class="margintop" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
import { purchaseplanid } from '@/api/scm-api'
export default {

  data() {
    return {
      fordisabled: false, // 禁用文本框
      planForm: {},
      planTable: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      rules: {

      }
    }
  },
  computed: {
  },
  mounted() {
    if (this.$route.query && this.$route.query.disabled) {
      this.fordisabled = true
    }
    this._purchaseplanid()
  },
  methods: {

    async _purchaseplanid(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await purchaseplanid(id)
        this.planForm = datas
        this.planForm.purchaseStatus = String(datas.purchaseStatus)
        this.planTable = datas.details?.map(v => { return { ...v, negative: v.negative ? '是' : '否' } })
      }
    },
    getTotal(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('合计')
          return
        }
        if (column.property === 'actualPairs') {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    cancle() {
      this.$router.push({
        path: '../',
        append: true
      })
    }

  }
}
</script>
<style lang="scss" scope>
.margintop {
  margin-top: 20px;
}
</style>

